




























































































































import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import { Notification } from "@/models/notification.interface";
import { CustomDate } from "@/mixins/custom-date";
import InnerImageZoom from "vue-inner-image-zoom";
import { PenaltyUser } from "@/models/penalty.interface";
import { Company } from "@/models/company.interface";

@Component({
  components: {
    Loader,
    DetailHeader,
    "inner-image-zoom": InnerImageZoom,
  },
})
export default class penaltyDetail extends mixins(Navigation, CustomDate) {
  loader = false;
  dialogPaymentDetail = false;
  paymentPreview = {};

  private async created(): Promise<void> {
    this.loader = true;

    await this.$store
      .dispatch("penalties/getPenalty", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message:
            this.$tc("Penalties.fetchError.penalty") + this.$route.params.id,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    this.setNavigation({
      previousRoute: "/staff/penalties",
      icon: "mdi-account-cancel",
      title: `${this.$tc("Views.pd-t")}: ${this.$route.params.id}`,
    });
    this.loader = false;
  }

  get penalty(): PenaltyUser {
    return this.$store.getters["penalties/getPenalty"];
  }
  get admin() {
    const admin = this.penalty.admin;
    if (admin) {
      return {
        name: `${admin.first_name} ${admin.last_name}`,
        alias: admin.alias,
        email: admin.email,
        phone: admin.phone,
      };
    }
    const company: Company = this.$store.getters["system/getCompany"];
    return {
      name: "SYSTEM",
      alias: "N/A",
      email: company.email,
      phone: company.phone,
    };
  }
}
